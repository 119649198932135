<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.partner_company") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div class="card p-4">
        <div
          class="grid grid-cols-1 gap-x-12 gap-y-4 p-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
        >
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.name") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ partner.name }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.type") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else-if="partner.types">{{
                partner.types
                  .map((partnerType) =>
                    t("partner_types." + getTypeName(partnerType, types))
                  )
                  .join(", ")
              }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.email") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ partner.email }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
            <div>
              <label class="block">{{ t("labels.phone") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ partner.phone }}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 class="mb-2">{{ t("headings.partner_estates") }}</h2>
        <div class="card">
          <custom-table
            :fields="fields"
            :items="partnerEstates"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="true"
            :searchFields="searchFields"
            :showHeader="true"
            sortBy="name"
            :sortFields="sortFields"
            @row-click="(id) => router.push(`/estates/${id}`)"
          >
          </custom-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getTypeName } from "../utils/typeUtils";
export default {
  components: { Breadcrumb, CustomTable, FormButton, Skeleton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const company = computed(() => store.state.company.company);
    const partner = computed(() => store.state.partner.partner);
    const partnerEstates = computed(() => store.state.estate.partnerEstates);
    const types = computed(() => store.state.partner.types);

    const waiting = computed(() => {
      if (store.state.estate.waiting) return true;
      if (store.state.partner.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "address",
        label: "address",
      },
      {
        key: "postalCode",
        label: "postal_code",
      },
      {
        key: "city",
        label: "city",
      },
    ]);
    const searchFields = ref(["name", "address", "postalCode", "city"]);
    const sortFields = ref(["address", "city", "name", "postalCode"]);

    onMounted(() => {
      store.dispatch("partner/getPartner", route.params.partnerId);
      if (!partnerEstates.value.length) {
        store.dispatch("estate/getPartnerEstates", {
          companyId: company.value.id,
          partnerId: route.params.partnerId,
        });
      }
      if (!types.value.length) {
        store.dispatch("partner/getTypes");
      }
    });

    return {
      company,
      getLocaleDate,
      getTypeName,
      fields,
      locale,
      partner,
      partnerEstates,
      route,
      router,
      searchFields,
      sortFields,
      t,
      types,
      waiting,
    };
  },
};
</script>
